import React from "react"
import { isEmpty } from "lodash"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { getCookie, getOgImage } from "../../utils/functions"
import ProductSingle from "../../components/productSingle/ProductSingle"
import "./style.scss"
import { Helmet } from "react-helmet"

const ProductSinglePage = props => {
  const {
    pageContext: { name, seo, uri },
  } = props
  // console.log(props)
  const { code: locale } = props.pageContext.wpmlTranslated.current[0]
  const { node: translated } = props.pageContext.wpmlTranslated
  const { pageContext } = props
  const lang = locale.substring(0, 2)
  return (
    <Layout lang={lang} page="productsSingle" translated={translated}>
      {!isEmpty(props.pageContext) ? (
        <>
          <SEO
            title={name}
            translated={translated}
            seoData={seo}
            uri={uri}
            lang={lang}
            page={"productsSingle"}
            // header={ siteTitle: 'Gatsby WooCommerce Theme' }}
            openGraphImage={getOgImage(seo)}
          />
          {getCookie("gatsby-gdpr") ? (
            <Helmet>
              <script
                type="text/javascript"
                ata-rh="true"
                data-react-helmet="true"
                src={`https://t.goadservices.com/engine/${
                  process.env.goPl
                }?id=${getCookie("__goadservices")}`}
              ></script>
              <script
                type="text/javascript"
                ata-rh="true"
                data-react-helmet="true"
              >
                {`
              var goadservicesq = goadservicesq || [];
              try {
                goadservicesq.push(
                    [
                        "_PRODUCT",
                        {
                            identifier: '${props.pageContext.databaseId}',
                            availability: '${
                              props.pageContext.stockQuantity > 0 ? 1 : 0
                            }'
                        }
                    ]

                );
              } catch (e) {
                  // console.log(e)
              }
            `}
              </script>
            </Helmet>
          ) : (
            ""
          )}
          <ProductSingle content={pageContext} lang={lang} />
        </>
      ) : (
        <div>Something went wrong</div>
      )}
    </Layout>
  )
}
export default ProductSinglePage

import React from 'react';
import { v4 as uuid } from 'uuid';
import Img from "gatsby-image"
import { withPrefix } from 'gatsby';
import Icon from "../../images/list_icon.svg"

const HeadingDesriptionImageList = ({ description, heading, list, img }) => {
  return (
    <section className={`productSingleContent__HeadingDesriptionImageList ${img ? '' : 'productSingleContent__HeadingDesriptionImageList--noImg'}`} style={{
      backgroundImage: `url(${withPrefix('/images/product_cat_bg.png')})`,
      backgroundPosition: 'left top',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    }}>
      <div className="container container">
        {img ? (
          <>
            <h2 className="productSingleContent__HeadingDesriptionImageList__heading heading--big heading--center heading--dash">
              <span dangerouslySetInnerHTML={{ __html: heading }}></span>
            </h2>
            {description ? (
              <div className="content">
                <div className="description" dangerouslySetInnerHTML={{ __html: description }}>
                </div>
              </div>
            ) : ''}
            <div className="productSingleContent__HeadingDesriptionImageList__wrapper">
              <div className="productSingleContent__HeadingDesriptionImageList__img">
                <Img fluid={img.imageFile.childImageSharp.fluid} />
              </div>
              <div className="productSingleContent__HeadingDesriptionImageList__content">
                <ul>
                  {list.map(item => (
                    <li key={uuid()} className="productSingleContent__HeadingDesriptionImageList__item">
                      <Icon />
                      <span dangerouslySetInnerHTML={{ __html: item.item }}></span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </>
        ) : (
          <>
            <h2 className="productSingleContent__HeadingDesriptionImageList__heading heading--big heading--center heading--dash">
              <span dangerouslySetInnerHTML={{ __html: heading }}></span>
            </h2>
            {description ? (
              <div className="content">
                <div className="description" dangerouslySetInnerHTML={{ __html: description }}>
                </div>
              </div>
            ) : ''}
            <div className="productSingleContent__HeadingDesriptionImageList__wrapper">
              <div className="productSingleContent__HeadingDesriptionImageList__content">
                <ul>
                  {list.map(item => (
                    <li key={uuid()} className="productSingleContent__HeadingDesriptionImageList__item">
                      <Icon />
                      <span dangerouslySetInnerHTML={{ __html: item.item }}></span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  )
}
export default HeadingDesriptionImageList

import React from 'react';
import { removeHost } from "../../utils/functions";
import { Link } from 'gatsby';
import Arrow from "../../images/breadcrum_arrow.svg"
import { v4 as uuid } from 'uuid';
import "./style.scss"
import { isEmpty } from 'lodash';


const Breadcrumbs = ({ breadcrumbsList }) => {
  console.log(breadcrumbsList);
  return (
    <>
    {!isEmpty(breadcrumbsList)? (

      <div className="breadcrumbs">
      {breadcrumbsList && breadcrumbsList.map(({ breadcrumb }, index) => (
        <div key={uuid()}>
          {breadcrumb.url !== '#' ? (
            <>
              <Link className="breadcrumbs__item" to={removeHost(breadcrumb.url)}>{breadcrumb.title}</Link>
              <Arrow />
            </>
          ) : (
            <span className="breadcrumbs__item">{breadcrumb.title}</span>
          )}
        </div>
      ))}
      
      </div>
      ): ''}
      </>
  )
}
export default Breadcrumbs

import React, { useContext, useEffect, useState } from 'react';
import ProductSingleBuilder from '../productSingleBuilder/ProductSingleBuilder';
import ProductsList from '../productsList/ProductsList';
import { withPrefix } from 'gatsby';
import { checkStock } from '../../utils/woocommerce';

import ProductSingleHero from '../productSingleHero/ProductSingleHero';
import { isHeroScrolledShowElement } from '../../utils/functions';
import ProductSingleBottom from '../productSingleBottom/ProductSingleBottom'
import { AppContext } from "../context/AppContext"

import "./style.scss"

const ProductSingle = (props, { lang }) => {
  const {
    buySingleText,
    buySubscriptionText,
    productSubscriptionAcf,
    content,
    textInProductButton,
    upSellProducts,
    headingBeforeUpSellProducts,
    mergeProducts,
    heroImg,
    footerAction,
    footerText
  } = props.content.acfSingleProduct

  const { breadcrumbsCategories, textOutOfStock, textAddToCart } = props.content.template.productSingle

  const {
    regularPrice,
    salePrice,
    customPrice,
    shortDescription,
    productTags,
    productCategories,
    name,
    databaseId
  } = props.content

  const { image } = props.content;

  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [canBuy, setCanBuy] = useState(false);
  const [currentProductID, setCurrentProductID] = useState(databaseId);
  const { addToCartError, addToCartErrorIdProduct } = useContext(AppContext)
  

  useEffect(() => {
    if (upSellProducts) {
      let tmp = upSellProducts.map(element => {
        return {
          ...element.product,
          image: element.product.featuredImage.node
        }
      })
      setProductList(tmp);
    }

    if ((typeof window !== "undefined")) {
      const elementHero = document.querySelector('.productSingleHero');
      const elementToShow = document.querySelector('.productSingleBottom');
      isHeroScrolledShowElement(elementHero, elementToShow)
    }
    setTimeout(()=>{
      getStock();
    }, 100)

  }, [])

  const getStock = async () => {
    setLoading(true)
    const checkStockData = await checkStock(currentProductID);
    setCanBuy(checkStockData)
    setLoading(false)

  }

  useEffect(() => {
    setLoading(false)
  }, [canBuy]);

  useEffect(() => {
    setLoading(false)
  }, [addToCartError]);
  

  return (
    <>
      <ProductSingleHero
        categories={productCategories}
        tags={productTags}
        title={name}
        description={shortDescription}
        lang={lang}
        mergerProducts={mergeProducts}
        regularPrice={regularPrice}
        customPrice={customPrice}
        salePrice={salePrice}
        addToCartText={textAddToCart}
        background={heroImg}
        breadcrumbsStart={breadcrumbsCategories}
        textOutOfStock={textOutOfStock}
        productId={currentProductID}
        singleProductId={databaseId}
        loading={loading}
        canBuy={canBuy}
        buySingleText={buySingleText}
        buySubscriptionText={buySubscriptionText}
        productSubscriptionAcf={productSubscriptionAcf}
        setCurrentProductID={setCurrentProductID}
        productImage={image}
        addToCartError={addToCartError}
        addToCartErrorIdProduct={addToCartErrorIdProduct}
      />

      <ProductSingleBuilder content={content} lang={lang} />
      {productList.length ? (
        <div className="productSingle__upSell" style={{
          backgroundImage: `url(${withPrefix('/images/product_cat_bg.png')})`,
          backgroundPosition: 'left top',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}>
          <div className="container">
            <h2 className="productSingle__upSell__heading heading--big heading--center heading--dash">
              <span dangerouslySetInnerHTML={{ __html: headingBeforeUpSellProducts }}></span>
            </h2>
            <ProductsList action='add' productBoxButton={textInProductButton} products={productList} columns={4} />
          </div>
        </div>
      ) : ''}
      <ProductSingleBottom
        footerAction={footerAction}
        footerText={footerText}
        productId={currentProductID}
        loading={loading}
        canBuy={canBuy}
        textOutOfStock={textOutOfStock}
      />
    </>
  )
}
export default ProductSingle
